import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { AboutLayout, Desi, Div } from '../components/desi-bio.js';
import SEO from '../../../gatsby-theme-courses/src/components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = AboutLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title='Biografia' mdxType="SEO" />
    <h2 {...{
      "id": "desi-maxwell"
    }}>{`Desi Maxwell`}</h2>
    <Div sx={{
      maxWidth: '300px',
      marginBottom: 3
    }} mdxType="Div">
  <Desi mdxType="Desi" />
    </Div>
    <p>{`Desi è cresciuto nella contea di Antrim, nell’Irlanda del Nord. Dopo aver studiato all’Università dell’Ulster e successivamente ai Seminari teologici di Westminster e Princeton, ha servito per sette anni nel ministero pastorale. Per i successivi venti anni ha insegnato al Belfast Bible College, prima di cercare di portare l’aula nel salotto attraverso ‘Xplorations’, un ministero di insegnamento che enfatizza le radice ebraiche. Desi ha co-fondato e dirige ‘Xplorations’ con sua moglie Heather.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      